<template>
     <div id="app" :class="{ darkmode: isdarkmode }">
          <div id="nav" class="container">
               <div class="logo">
                    <router-link to="/">Slyde.ai<span class="alpha">alpha</span></router-link>
               </div>
               <div class="navlinks">
                    <span><router-link :to="{ name: 'FAQ' }">FAQs</router-link></span>
                    <span><router-link :to="{ name: 'ABOUT' }">About</router-link></span>
                    <span>Contact</span>
                    <span>
                         <div class="mode-toggle" @click="modeToggle" :class="{ 'darkmode-toggled': isdarkmode }">
                              <div class="toggle">
                                   <div id="dark-mode" type="checkbox"></div>
                              </div>
                         </div>
                    </span>
               </div>
          </div>
          <div class="container">
               <FadeTransition :duration="200" mode="out-in">
                    <router-view />
               </FadeTransition>
          </div>
     </div>
</template>

<script>
     import { FadeTransition } from "vue2-transitions";

     export default {
          data() {
               return {
                    isdarkmode: JSON.parse(localStorage.getItem("isdarkmode")),
               };
          },
          components: {
               FadeTransition,
          },
          created() {
               if (!("isdarkmode" in localStorage)) {
                    this.isdarkmode = Math.random() > 0.5;
                    localStorage.setItem("isdarkmode", this.isdarkmode);
                    this.$store.state.isdarkmode = this.isdarkmode;
               } else {
                    this.$store.state.isdarkmode = this.isdarkmode;
               }
          },

          methods: {
               modeToggle() {
                    this.isdarkmode = !this.isdarkmode;
                    localStorage.setItem("isdarkmode", this.isdarkmode);
                    this.$store.state.isdarkmode = this.isdarkmode;
               },
          },
     };
</script>
<style lang="scss">
     @import "@/assets/styles/variables.scss";

     #app {
          background: url("./assets/images/Vector1.png"), url("./assets/images/Vector2.png"), url("./assets/images/Vector4.png"),
               url("./assets/images/Vector3.png");

          background-position: top 80px left -20px, top -30px left 60%, top 80% right -25px, top 50% left -60px;

          background-size: 70px auto, 64px auto, 64px auto, 127px auto;
          background-repeat: no-repeat;
          min-height: 100vh;
          background-color: $bg_light;
     }
     #app.darkmode {
          background-color: $bg_dark;
          #nav {
               .navlinks {
                    a {
                         color: $tertiary_dark !important;
                    }

                    a.router-link-exact-active.router-link-active {
                         color: #f2836b !important;
                    }
               }
          }

          .logo {
               a {
                    color: $secondary_dark;
               }
          }

          
     }
     .alpha {
          font-size: 13px;
          position: relative;
          top: -22px;
          right: -3px;
          // background: #f2836b;
          padding: 3px;

          color: #f2836b;
     }
     .logo {
          text-align: center;
          a {
               color: $secondary_light;
               font-weight: bold;
               font-size: 28px;
          }
     }
     #nav {
          padding: 40px 0 20px;
          display: flex;

          .navlinks {
               margin-left: auto;
               order: 2;
               span {
                    margin-left: 40px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14.8095px;
                    position: relative;
                    top: 10px;
               }

               a {
                    color: $tertiary_light !important;
               }

               a.router-link-exact-active.router-link-active {
                    color: #f2836b !important;
               }
          }
     }
     @media only screen and (max-width: 600px) {
          #app {
               background-position: top 30px left -28px, top -30px left 90%, top 65% right -40px, top 80% left -80px;

               background-size: 50px auto, 54px auto, 64px auto, 127px auto;
          }

          #nav {
               padding: 40px 0 20px;
               display: block;

               .navlinks {
                    margin: auto;
                    text-align: center;
                    span {
                         margin: 0;
                         display: inline-block;
                         padding: 0 15px;
                    }
               }
          }
     }

     /*================================ theme switch ==============================*/
     $dark: #171717;
     $mode-toggle-bg: #262626;
     $sun-bg: rgba(0, 0, 0, 0.3);
     $sun-bg: lighten(skyblue, 17);
     $moon-bg: rgba(255, 255, 255, 0.3);

     $sun: #ffd800;
     $moon: white;
     .mode-toggle {
          display: inline-block;
          position: relative;
          top: 7px;
          //margin: auto .5rem .5rem auto;
          padding: 0;
          width: 44px;
          height: 24px;
          min-width: 36px;
          min-height: 20px;
          background-color: $sun-bg;
          border: 0;
          border-radius: 24px;
          outline: 0;
          overflow: hidden;
          cursor: pointer;
          z-index: 2;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-touch-callout: none;
          appearance: none;
          transition: background-color 0.5s ease;

          .toggle {
               position: absolute;
               top: 0;
               left: 2px;
               bottom: 0;
               margin: auto;
               width: 20px;
               height: 20px;
               border-radius: 50%;

               box-shadow: inset 0 0 0 2px $sun;
               overflow: hidden;
               transition: transform 0.5s ease;

               #dark-mode {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 50%;

                    &:before {
                         content: "";
                         position: relative;
                         width: 100%;
                         height: 100%;
                         left: 10%;
                         float: left;
                         background-color: $sun;
                         transition: border-radius 0.5s ease, width 0.5s ease, height 0.5s ease, left 0.5s ease, transform 0.5s ease;
                    }
               }
          }
     }
     .darkmode .mode-toggle {
          // background-color: lighten($mode-toggle-bg, 5%);
          background-color: $moon-bg;

          .toggle {
               transform: translateX(19px);
               box-shadow: inset 0 0 0 2px $moon;

               #dark-mode {
                    &:before {
                         background-color: $moon;
                         border-radius: 50%;
                         width: 150%;
                         height: 85%;
                         left: 40%;
                         transform: translate(-10%, -40%), rotate(-35deg);
                    }
               }
          }
     }
</style>
