import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
     {
          path: "/",
          name: "Home",
          component: () => import("../views/Intro.vue"),
     },
     {
          path: "/home",
          name: "HOME",
          component: () => import("../views/Home.vue"),
     },
     {
          path: "/result",
          name: "RESULT",
          component: () => import("../views/Result.vue"),
     },
     {
          path: "/faq",
          name: "FAQ",
          component: () => import("../views/Faq.vue"),
     },
     {
          path: "/about",
          name: "ABOUT",
          component: () => import("../views/About.vue"),
     },
];

const router = new VueRouter({
     mode: "history",
     base: process.env.BASE_URL,
     routes,
});

export default router;
