import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
     state: {
          dataJSON: undefined,
          isdarkmode: false,
     },
     mutations: {},
     actions: {},
     modules: {},
});
